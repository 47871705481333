import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'

class Cookies extends React.Component {
    render() {

        return (
            <div>
            <section className="hero is-info is-medium is-bold">
                <Header />
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <h1 className="title"> Cookies </h1>
                        <div className="columns">

                        </div>

                    </div>
                </div>
            </section>
            <section className="section">
    <div className="columns features">
        <div className="column">
            <div className="container">
                <p>
                    This websites uses Google Analytics which creates and uses the following cookies.

                </p>
                <br />
                <div className="table-container">

                <table className="table is-bordered is-striped is-narrow">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Expiration</th>
                            <th>Description</th>
                        </tr>
                        <tr>
                            <td><code translate="no" dir="ltr"><span>_ga</span></code></td>
                            <td>2 years</td>
                            <td>Used to distinguish users.</td>
                        </tr>
                        <tr>
                            <td><code translate="no" dir="ltr"><span>_gid</span></code></td>
                            <td>24 hours</td>
                            <td>Used to distinguish users.</td>
                        </tr>
                        <tr>
                            <td><code translate="no" dir="ltr"><span>_gat</span></code></td>
                            <td>1 minute</td>
                            <td>Used to throttle request rate. If Google Analytics is deployed via Google Tag Manager,
                                this
                                cookie will be named <code translate="no"
                                    dir="ltr"><span>_dc_gtm_&lt;property-id&gt;</span></code>.</td>
                        </tr>
                        <tr>
                            <td><code translate="no" dir="ltr"><span>AMP_TOKEN</span></code></td>
                            <td>30 seconds to 1 year</td>
                            <td>Contains a token that can be used to retrieve a Client ID from AMP Client ID service.
                                Other
                                possible values indicate opt-out, inflight request or an error retrieving a Client ID
                                from
                                AMP
                                Client ID service.</td>
                        </tr>
                        <tr>
                            <td><code translate="no" dir="ltr"><span>_gac_&lt;property-id&gt;</span></code></td>
                            <td>90 days</td>
                            <td>Contains campaign related information for the user. If you have
                                linked your Google Analytics and Google Ads accounts, Google Ads
                                website conversion tags will read this cookie unless you opt-out. <a
                                    href="//support.google.com/google-ads/answer/7521212">Learn more</a>. </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>
</section>
        <Footer />
        </div>
        )
    }
}
export default Cookies