import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'

class About extends React.Component {
    render() {

        return (
            <div>
            <section className="hero is-info is-medium is-bold">
                <Header />
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <h1 className="title"> About </h1>
                        <div className="columns">

                        </div>

                    </div>
                </div>
            </section>
            <section className="section">
            <div className="columns features">
                <div className="column">
                    <div className="container">
        
        
                        <p>
                            This website was created using <a href="https://data.gov.uk/dataset/e3939ef8-30c7-4ca8-9c7c-ad9475cc9b2f/anonymised-mot-tests-and-results">anonoymised
                                MOT test data</a> from the Deparment for Transport and is used under the <a href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/">Open Goverment
                                Licence.</a>
                        </p>
                        <br />
                        <p>
                            Cars are only shown if over 100 MOT tests were perfomed on that make and model. Additionally only
                            models
                            with 3 or more cars of the same year and 10 or more of the same mileage (within 10,000 miles) are
                            shown in the graphs.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </div>
        )
    }
}
export default About