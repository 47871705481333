import React from 'react';
import { Link } from 'react-router-dom'

class CarSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            make_models: [],
            models: [],
            selected: {}
        }
        this.makeSelected = this.makeSelected.bind(this);

    }

    GetLink() {
        // Store selection in dom props
        //https://stackoverflow.com/questions/29108779/how-to-get-selected-value-of-a-dropdown-menu-in-reactjs
        let make = this.state.selected.make
        let model = this.state.selected.model
        if (make && model) {
            return "/car/" + make + "/" + model
        }
        return ""
    }


    componentDidMount() {
        fetch('/api/models')
            .then(res => res.json())
            .then((data) => {
                console.log(data)
                this.setState({ make_models: data })
            })
            .catch(console.log)


    }

    makeSelected(event) {
        // Take selected make and set the models to be the same
        let target = event.target

        if (target.id === "make") {
            this.setState(prevState => {
                let selected = {}
                selected[target.id] = target.value;
                return { selected };
            })

        } else {
            this.setState(prevState => {
                let selected = Object.assign({}, prevState.selected);
                selected[target.id] = target.value;
                return { selected };
            })
        }
    }

    render() {
        var makeItems = Object.keys(this.state.make_models).map((make) => (
            <option key={make} value={make}>{make}</option>
        ));

        if (this.state.selected.make) {
            var modelItems = this.state.make_models[this.state.selected.make].map((model) => (
                <option key={model} value={model}>{model}</option>
            ));
        }

        return (
            <div>
                <div className="columns">
                    <div className="column is-half">
                        <div className="select is-medium is-fullwidth">
                            <select id="make" defaultValue={'DEFAULT'} onChange={this.makeSelected} required="">
                                <option value="DEFAULT" disabled>Make</option>
                                {makeItems}
                            </select>
                        </div>
                    </div>
                    <div className="column is-half">
                        <div className="select is-medium is-fullwidth">
                            <select id="model" required="" onChange={this.makeSelected}>
                                <option disabled="" value="">Model</option>
                                {modelItems}
                            </select>
                        </div>
                    </div>

                </div>
                <div className="columns">
                    <div className="container has-text-centered">

                        <Link to={this.GetLink()} id="btn-find" className="button is-primary is-medium">Find</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default CarSelector;