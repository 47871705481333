import React from 'react'
import { Line } from 'react-chartjs-2';
import XYChartLoader from '../loaders/XYChartLoader';

class YearGraph extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            yearData: [],
            loading: true
          }
    }

    componentDidMount() {
        fetch("/api/" + this.props.make + "/" + this.props.model + "/graph/year")
            .then(res => res.json())
            .then((data) => {
                this.setState({ yearData: data })
                this.setState({ loading: false })
            })
            .catch(console.log)
    }


    render() {

        const yearChart = {
            labels: this.state.yearData.map(car => car.year),
            datasets: [{
                label: 'Pass Rate',
                data: this.state.yearData.map(car => car.pass_rate),
                fill: false,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 5
            }]
        }


        const yearOptions = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            return value * 100 + '%';
                        }
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Pass Rate'
                    }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Year'
                    }
                }]
            },
            tooltips: {
                position: 'nearest',
                displayColors: false,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem, data) {
                        var label = data.datasets[tooltipItem.datasetIndex].label || '';

                        if (label) {
                            label += ': ';
                        }
                        label += Math.round(tooltipItem.yLabel * 100) + "%";
                        return label;
                    }
                }
            },
            animation: false,
        };

        return (

            
            this.state.loading ? <XYChartLoader /> :  <Line height={300}  data={yearChart} options={yearOptions} />

        )
    }
}
export default YearGraph