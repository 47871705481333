import React from 'react'
import { Line } from 'react-chartjs-2';
import XYChartLoader from '../loaders/XYChartLoader';

class MileageGraph extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            mileageData: [],
            loading: true
          }
    }

    componentDidMount() {
        fetch("/api/" + this.props.make + "/" + this.props.model + "/graph/mileage")
            .then(res => res.json())
            .then((data) => {
                this.setState({ mileageData: data })
                this.setState({ loading: false })
            })
            .catch(console.log)
    }


    render() {

        const mileageChart = {
            labels: this.state.mileageData.map(car => car.test_mileage),
            datasets: [{
              label: 'Pass Rate',
              data: this.state.mileageData.map(car => car.pass_rate),
              fill: false,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)'
              ],
              borderColor: [
                'rgba(20, 120, 200, 0.8)'
              ],
              borderWidth: 5
            }]
          }

          const mileageOptions = {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return value * 100 + '%';
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Pass Rate'
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Mileage'
                }
              }]
            },
            tooltips: {
              position: 'nearest',
              mode: 'index',
              intersect: false,
              displayColors: false,
              callbacks: {
                label: function (tooltipItem, data) {
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';
      
                  if (label) {
                    label += ': ';
                  }
                  label += Math.round(tooltipItem.yLabel * 100) + "%";
                  return label;
                },
                title: function (tooltipItems, data) {
                  var t = tooltipItems[0].xLabel;
                  return t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Miles";
                }
              }
            },
            animation: false
          };


        return (
          this.state.loading ? <XYChartLoader /> :  <Line height={300} data={mileageChart} options={mileageOptions} />
        )
    }
}
export default MileageGraph