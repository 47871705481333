import React from 'react'
import TopCard from './topcard'
import numberWithCommas from '../../format_number'

class Heaps extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            yearData: [],
            done: undefined
        }
    }

    getSubtitle(car) {
        return numberWithCommas(Math.round(car.pass_rate*100)) + "% Pass Rate"
    }

    componentDidMount() {
        fetch("api/top/worst")
            .then((res) => {
                this.setState({ httpStatus: res.status })
                res.json().then((data) => {
                    var carItems = data.map((car) => (
                        {make: car.make, model: car.model, subtitle: this.getSubtitle(car)}
                    ));
                    this.setState({ cars: carItems })
                })
            })
            .catch(console.log)
    }


    render() {

        
        return (
            < TopCard title="Heaps" emoji="🗑️" cars={this.state.cars} httpStatus={this.state.httpStatus} />
        )
    }
}

export default Heaps