import React from 'react';
import { Redirect } from 'react-router-dom'

class RegSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            reg: '',
            regNotFound: false
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }


    componentDidMount() {

    }

    onSubmit(event) {
        // Strip spaces from reg.
        let regWithoutSpaces = this.state.reg.replace(' ', '')
        // Only check if reg is not empty.
        if (regWithoutSpaces !== "") {
            fetch('/api/reg/' + regWithoutSpaces)
                .then((res) => {
                    if(res.status !== 200){
                        // Something's not gone well.
                        throw new Error('Network response was not ok');
                    }
                    return res.json()
                })
                .then((data) => {
                    // Sets state.make and state.model.
                    this.setState(data)
                })
                .catch((error) => {
                    this.setState({ regNotFound: true })
                })
            }

        event.preventDefault();

    }

    onChange(event) {
        const re = /^[a-z0-9 ]+$/i
        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ reg: event.target.value });
        }

    }

    render() {

        if (this.state.make && this.state.model) {
            // Redirect to car page.
            let link = "/car/" + this.state.make + "/" + this.state.model
            return <Redirect push to={link} />
        }

        return (
            <form onSubmit={this.onSubmit}>
                <div className="columns is-centered">
                    <div className="column is-half ">
                        <input onChange={this.onChange} value={this.state.reg} className="input is-large has-text-centered numberplate" type="text" placeholder="AB12 DEF" maxLength="8" />
                        {this.state.regNotFound ? (<p className="help is-warning">This car cannot be found :( </p>) : (null)}

                    </div>


                </div>
                <div className="columns">
                    <div className="container has-text-centered">

                        <button type="submit" id="btn-find" className="button is-primary is-medium">Find</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default RegSelector;