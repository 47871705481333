import React from 'react'
import ContentLoader from 'react-content-loader'

const ThreeDots = props => {
  return (
    <ContentLoader
      viewBox="0 0 80 52"
      speed={2}
      backgroundColor="transparent"
      {...props}
    >
      <circle cx="8" cy="10" r="8" />
      <circle cx="40" cy="10" r="8" />
      <circle cx="72" cy="10" r="8" />
    </ContentLoader>
  )
}

export default ThreeDots