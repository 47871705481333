import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'

class NotFound extends React.Component {
  render() {
    return (

      <div>

        <section className="hero is-info is-medium is-bold">
          <Header fill="blue" />
        </section>

        <section className="container section">
          <div className="columns">
            <div className="column is-full">
              <div className="card">
                <div className="card-content">
                  <div className="content has-text-centered">
                    <h1 className="title"> 404 - Car Not Found.</h1>
                    <p>
                      It doesn't seem to be where we left it! Try another one.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

    )
  }
}
export default NotFound