import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import NotFound from './404.js'

import numberWithCommas from '../format_number'
import YearGraph from '../components/year_graph';
import MileageGraph from '../components/mileage_graph';
import CarIcon from '../caricon'
import ThreeDots from '../loaders/ThreeDots';
 
class CarPage extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      car: {
      },
      done: undefined
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;

    fetch("/api/" + encodeURI(params.make) + "/" + encodeURI(params.model))
      .then((res) => {
        console.log("here")
        if(res.status === 200){
            return res;
        } else {
            this.setState({ httpStatus: res.status })
            throw new Error('error');
        }
      })
      .then(res => res.json())
      .then((data) => {
        this.setState({ car: data })
        this.setState({ done: true })
      })
      .catch(error => {
      })

  }


  render() {
    if(this.state.httpStatus === 404){
      return <NotFound />
    }

    if(this.state.done){
      var passrate = (this.state.car.pass_rate * 100).toFixed()
      var numberOfTests = numberWithCommas(this.state.car.count)
      var averageMileage = numberWithCommas(this.state.car.test_mileage.toFixed())
      var averageYear = this.state.car.year
      // Set description tag
     document.getElementsByTagName("META").namedItem("description").content = `Will your ${this.state.car.make} ${this.state.car.model} pass its next MOT? Of the ${numberOfTests} tests ${passrate}% passed the first time round. View graphs and more statistics!`
     document.title = "Will My " + this.state.car.make + " " + this.state.car.model + " Pass its MOT?";
    }

  
    return (

      <div>

        <section className="hero is-info is-medium is-bold">
          <Header fill="blue" />
        </section>



        <section className="container section">
          <div className="columns">
            <div className="column is-full">
              <div className="card">
                <div className="card-content">
                  <div className="content">

                    {!this.state.done ? (
                      <div className="has-text-centered"><ThreeDots width={50} /></div>
                    ) : (
                        <h1 className="has-text-centered">
                          {this.state.car.make} <span className="has-text-weight-light">{this.state.car.model}</span>
                        </h1>
                      )}
                    <div className="card-image has-text-centered ">
                      <div><CarIcon height="160px" fill="#147efb" /></div>
                    </div>
                    <nav className="level">
                      <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">Pass Rate</p>
                          {!this.state.done ? (
                            <ThreeDots width={50} />
                          ) : (
                              <p className="title">{passrate}%</p>
                            )}
                        </div>
                      </div>
                      <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">Number of tests</p>
                          {!this.state.done ? (
                            <ThreeDots width={50} />
                          ) : (
                              <p className="title">{numberOfTests}</p>
                            )}
                        </div>
                      </div>
                      <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">Average Mileage</p>
                          {!this.state.done ? (
                            <ThreeDots width={50} />
                          ) : (
                              <p className="title">{averageMileage}</p>
                            )}
                        </div>
                      </div>
                      <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">Average Year</p>
                          {!this.state.done ? (
                            <ThreeDots width={50} />
                          ) : (
                              <p className="title">{averageYear}</p>
                            )}
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    Year of Production
                    </p>
                </header>
                <div className="card-content">
                  <div className="content graph">
                    <YearGraph make={this.props.match.params.make} model={this.props.match.params.model} />

                  </div>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    Mileage
                    </p>
                </header>
                <div className="card-content">
                  <div className="content graph">
                    <MileageGraph make={this.props.match.params.make} model={this.props.match.params.model} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

    )
  }
}
export default CarPage