import React from 'react';
import { Link } from 'react-router-dom'
import title from '../title.png';
import CookieConsentBar from './cookie_consent_bar';

class Header extends React.Component {
  
  componentDidMount(){
    // Menu burger
    var burger = document.querySelector('.burger');
    var menu = document.querySelector('#' + burger.dataset.target);
    burger.addEventListener('click', function () {
        burger.classList.toggle('is-active');
        menu.classList.toggle('is-active');
    });
  }

  render() {
    return (

        <section className="hero is-info is-medium is-bold">
          <CookieConsentBar />
          <div className="hero-head">
            <nav className="navbar">
              <div className="container">
                <div className="navbar-brand">
                <Link to="/" className="navbar-item" href="/">
                    <img src={title} alt="Logo" />
                  </Link>
                  <span className="navbar-burger burger" data-target="navbarMenu">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <div id="navbarMenu" className="navbar-menu">
                  <div className="navbar-end">
                    <div className="tabs is-right">
                      <ul>
                        <li className="is-active"><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </section>
    );
  }
}

export default Header;