import React from 'react';
import { Link } from 'react-router-dom';

class CookieConsentBar extends React.Component {

  constructor(props) {
    super(props)
    
    var showBar = true
    if(this.cookieExists("cookie_consent")){
      showBar = false
    }

    this.state = {
      show: showBar
    }

    this.closed = this.closed.bind(this);

  }

  
  componentDidMount(){
    // Menu burger
    var burger = document.querySelector('.burger');
    var menu = document.querySelector('#' + burger.dataset.target);
    burger.addEventListener('click', function () {
        burger.classNameList.toggle('is-active');
        menu.classNameList.toggle('is-active');
    });
  }

  cookieExists(cookieName) {
    let cookieArr = document.cookie.split(';');

    for(var i=0; i< cookieArr.length; i++) {
        if(cookieArr[i].split('=')[0].trim() === cookieName){
            return true;
        }        
    }
    return false;
  }

  closed(){
    this.setState({show: false})

    console.log("Closed")
    document.cookie = "cookie_consent=True";

  }

  render() {
    console.log(this.state.show)
    if(this.state.show){
      return (
        <div id="cookie-message" style={{backgroundColor: "#1086bb"}}>
            <div className="container has-text-centered" style={{padding: "0.5rem"}}>
                <div className="columns is-mobile">
                    <div className="column is-11">
                        <span className="is-vcentered is-size-7">Will My Car Pass uses cookies. By using this website or closing
                            this
                            message you agree to <Link to="/cookies" className="has-text-primary">our use of cookies.</Link></span>
                    </div>
                    <div className="column is-1">
                        <button id="cookie-close" className="delete is-pulled-right" onClick={this.closed}></button>
                    </div>
                </div>
            </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default CookieConsentBar;