import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import withTracker from './withTracker';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import CarPage from './pages/car'
import About from './pages/about'
import * as serviceWorker from './serviceWorker';
import NotFound from './pages/404';
import ScrollToTop from './scroll_to_top'
import Cookies from './pages/cookies';


const routing = (
    <Router>
      <ScrollToTop />
      <div>
        <Switch>
          <Route exact path="/" component={withTracker(App)} />
          <Route path="/car/:make/:model" component={withTracker(CarPage)} />
          <Route path="/about" component={withTracker(About)} />
          <Route path="/cookies" component={withTracker(Cookies)} />

          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  )


ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
