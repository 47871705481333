import React from 'react';

import Header from '../components/header';
import Footer from '../components/footer';
import '../App.css';
import 'bulma/css/bulma.css';

import CarSelector from '../components/car_selector';
import HighMileageHeros from '../components/cards/high_mileage_hero';
import Heaps from '../components/cards/heaps';
import GoldenOldies from '../components/cards/golden_oldies';
import RegSelector from '../components/reg_selector';



function App() {
  return (
    <TodoApp />
  );
}

class TodoApp extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showMakeModel: false
    }
    this.switchCarChooser = this.switchCarChooser.bind(this);
    this.text = "or choose make and model"
  }

  switchCarChooser(){
    // Toggle between reg selector and make/model selector.
    this.setState({showMakeModel: !this.state.showMakeModel})
  }

  render() {
    document.title = "Will My Car Pass its MOT?";

    return (
      <div>
        <Header />
        <section className="hero is-info is-medium is-bold">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title"> Find Your Car </h1>
              <h2 className="subtitle">
                See how likely your car is to pass its next MOT
              </h2>
              
              { this.state.showMakeModel ? <CarSelector/>  : <RegSelector /> }

              
              <div className="columns">
                <div id="switch-selector" className="column is-size-7 has-text-centered"> 
                  <a onClick={this.switchCarChooser}>{ this.state.showMakeModel ? "or enter registration"  : "or choose make and model" }</a>
                </div>
              </div>
            </div>

          </div>
          <div className="box cta">
            <p className="has-text-centered">
              <span className="tag is-primary">New</span> Passrate vs Mileage Graphs for Every Car!
            </p>
          </div>
        </section>
        <section className="container">
        <div className="columns features">
          <div className="column is-one-third">
            <HighMileageHeros />
          </div>
          <div className="column is-one-third">
            <Heaps />
          </div>
          <div className="column is-one-third">
            <GoldenOldies />
          </div>
        </div>
        </section>
        <Footer />
      </div>
    );
  }

}


export default App;
