import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="content has-text-centered">
                        <div className="control level-item">
                           <Link to="/cookies"><span className="is-size-7">Cookie Policy</span></Link>
                        </div>
                        <div className="control level-item section">
                            <div className="tags has-addons">
                                <span className="tag is-dark">Made By</span>
                                <span className="tag is-info">Jamie</span>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;