import React from 'react'
import { Link } from 'react-router-dom'
import { BulletList } from 'react-content-loader'

class TopCard extends React.Component {

    render() {

        var carItems;
        var error;
        if(this.props.cars){
            carItems= this.props.cars.map((car) => (
                <div key={car.make + car.model}>   
                <h5 className="is-marginless"><Link to={"car/" + car.make +  "/" + car.model}>{car.make} {car.model}</Link></h5>
                <h6 className="has-text-weight-normal">{car.subtitle}</h6>
                </div>
            ));
        } else if(this.props.httpStatus === 501){
            error = "🚧 Coming soon 🚧"
        } else if (this.props.httpStatus){
            error = "Uh oh! Something's gone wrong"
        } else {
            carItems = <BulletList width={"100%"} />
        }
    
        return (
            <div className="card is-shady">
                <div className="card-image has-text-centered">
                    <i className="fa"><span role="img" aria-label="emoji">{this.props.emoji}</span></i>
                </div>
                <div className="card-content">
                    <div className="content">
                        <h3>{this.props.title} </h3>
                        <h4>{error}</h4>
                        {carItems}
                    </div>
                </div>
            </div>

        )
    }
}
export default TopCard